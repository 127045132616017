import React, {useState} from "react";
import { Navigate } from 'react-router-dom'
import { useContext } from "react/cjs/react.development";
import { AuthContext } from './Auth'
import authConfig from '../config'
import { signInWithGoogle } from "./Auth";



const DashBoard = () => {
    const {loading, currentUser,currentUid,currentAddress } = useContext(AuthContext);

    return (
        <>
        {currentUser ? (
                    
                    <div>
                        You are logged in 
                        <div>
                            <div className="container mt-5">
                                <button onClick={() => authConfig.signOut()} className="btn btn-danger">Sign Out</button>
                            </div>
                        </div>
                        <div className="App">
                        <h1>UID:{currentUid}</h1>
                        <h1>UID:{currentAddress}</h1>
                        <h1>{localStorage.getItem("name")}</h1>
                        <h1>{localStorage.getItem("email")}</h1>
                        <img src={localStorage.getItem("profilePic")} />
                        </div>
                    </div>
                    
                ) : (
                    <p>
                        <button className="btn btn-primary" onClick={signInWithGoogle}>
                            Sign in with Google
                        </button>
                    </p>
                )}
        </>
    )


}

export default DashBoard;