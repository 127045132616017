import React, {useState,useContext,useEffect} from "react";
import { AuthContext } from './Auth'
import { Navigate} from 'react-router-dom'
import authConfig, { createUserDocument } from '../config';


const Testc = () => {
    const [id, setID] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setShouldRedirect(true);
  };

  const handleChange = (e) => {
    // setID(e.target.value);
  }

  return shouldRedirect ? (
    <Navigate to="/home" />
  ) : (
    <div>
      <div>
        <h1>Create new selection</h1>
        <form onSubmit={handleSubmit}>
          <label>Project Name</label>
          <input onChange={handleChange} type="text" name="projectName" required />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Testc;