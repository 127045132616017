import React from 'react'
import { BrowserRouter as  Router, Routes, Route } from "react-router-dom";
import Home from './components/Home'
import Dashboard from './components/Dashboard'
import LogIn from './components/LogIn'
import SignUp from './components/SignUp'
import Signup2 from './components/Signup2'
import { AuthProvider } from './components/Auth'
import Testc from './components/Testc';
import Load from './components/Load';
import { LoadBundleTask } from 'firebase/firestore';
import SignIn from './components/SignIn';



function App() {
  return ( 
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup2" element={<Signup2 />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/load" element={<Load />} />
          <Route path="/test" element={<Testc />} />
        </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
