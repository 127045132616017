import React, {useState,useContext,useEffect} from "react";
import { AuthContext } from './Auth'
import { Navigate} from 'react-router-dom'
import authConfig, { createUserDocument } from '../config';
import { signInWithGoogle } from "./Auth";


const SignIn = () => {
    const {currentUser,currentUid,currentAddress,loading } = useContext(AuthContext);

    if (loading) {
        return <p>Loading...</p>;
    }
    if (currentAddress) {
        return <Navigate to="/home" />
    }
    else if (currentUser && !currentAddress) {
        return <Navigate to="/signup" />;

    }
    return (
        <>
        <div className="hold-transition login-page">
<div className="login-box">
  <div className="login-logo">
    <a href="../../index2.html"><b>The Connect 37 </b>(unofficial)</a>
  </div>
  {/* /.login-logo */}
  <div className="card">
    <div className="card-body login-card-body">
      <p className="login-box-msg">เข้าสู่ข้อมูลโดยใช้ Google</p>

      <div className="social-auth-links text-center mb-3">
        <button className="btn btn-block btn-danger" onClick={signInWithGoogle}>
        <i className="fab fa-google mr-2" />
                            Sign in with Google
        </button>
      </div>
      {/* /.social-auth-links */}

    </div>
    {/* /.login-card-body */}
  </div>
</div>
{/* /.login-box */}
</div>
        </>
    )
    
    // return (
    //     <>
    //     {currentUser ? (
                    
    //                 <div>
    //                     You are logged in 
    //                     <div>
    //                         <div className="container mt-5">
    //                             <button onClick={() => authConfig.signOut()} className="btn btn-danger">Sign Out</button>
    //                         </div>
    //                     </div>
    //                     <div className="App">
    //                     <h1>UID:{currentUid}</h1>
    //                     <h1>UID:{currentAddress}</h1>
    //                     <h1>{localStorage.getItem("name")}</h1>
    //                     <h1>{localStorage.getItem("email")}</h1>
    //                     <img src={localStorage.getItem("profilePic")} />
    //                     </div>
    //                 </div>
                    
    //             ) : (
    //                 <p>
    //                     <button className="btn btn-primary" onClick={signInWithGoogle}>
    //                         Sign in with Google
    //                     </button>
    //                 </p>
    //             )}
    //     </>
    // )
}

export default SignIn;