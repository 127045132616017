import React, {useState,useContext,useEffect} from "react";
import { AuthContext } from './Auth'
import { Navigate} from 'react-router-dom'
import authConfig, { createUserDocument } from '../config';


const Load = () => {
    const [show, setShow] = useState(false)
    const {loading, currentUser,currentUid,currentAddress } = useContext(AuthContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log(currentAddress);         
        setShow(true);    
    }, 500)
     
    return () => clearTimeout(timeout)

  }, [show])

  function refreshPage() {
    setTimeout(()=>{
      window.location.reload(false);
  }, 1000);
  }

  if (show<1) return <>Loading{currentAddress}</>
  if (!currentAddress) { 
    refreshPage()
  }
  

  return  <> <Navigate to="/" /> </> 
}

export default Load;