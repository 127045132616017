import React, {useState, useContext} from "react";
import { Link,Navigate } from 'react-router-dom'
import { AuthContext } from './Auth'
import authConfig from '../config'
import { signOutGoogle } from "./Auth";

export default function Header() {
  const {currentAddress } = useContext(AuthContext);

    return (
<>

  {/* Navbar */}
  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* Left navbar links */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="#" className="nav-link">Home</a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="#" className="nav-link">Info</a>
      </li>
    </ul>
    {/* Right navbar links */}
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
          <i className="fas fa-expand-arrows-alt" />
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
          <i className="fas fa-th-large" />     
        </a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="#" className="nav-link">บ้านเลขที่ : {currentAddress}  </a> 
      </li>
      <li className="nav-item">
        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
          <i className="fas fa-envelope" />              
        </a>        
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="#" className="nav-link">{localStorage.getItem("email")}</a> 
      </li>
      <li className="nav-item">
        <button onClick={signOutGoogle}  className="btn btn-danger">Sign Out</button>
      </li>
    </ul>
  </nav>
  {/* /.navbar */}
</>

    )
}
