import React, { useContext } from 'react'
import { Link,Navigate } from 'react-router-dom'
import { AuthContext } from './Auth'
import { signInWithGoogle } from "./Auth";
import authConfig from '../config'
import Header from './Header'
import Menu from './Menu';
import Footer from './Footer';
import Content from './Content';

const Home = () => {
    const {loading, currentUser,currentUid,currentAddress } = useContext(AuthContext);

    if (loading) {
        return <p>Loading...</p>;
    }
    
    if (!currentUser) {
        return <Navigate to="/signin" />;
    }
    else if (currentUser && !currentAddress) {
        return <Navigate to="/signup" />;

    }

    return (
        <> 
            <div className="wrapper">
                <Header />
                <Menu />
                <Content />
                <Footer />
            </div>      
        </>
    )
}

export default Home;