import React, { useState, useEffect } from 'react'
import authConfig , {authProvider,firestore} from '../config'
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, child, get } from "firebase/database";



export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUid, setCurrentUid] = useState(null);
    const [currentAddress, setCurrentAddress] = useState(null);

    useEffect(() => {
        authConfig.onAuthStateChanged((user) => {
            setLoading(true);
            setCurrentUser(user);
            if (user) {
                // console.log("uid:"+user.uid);
                setCurrentUid(user.uid);
                // const userRef = firestore.doc(`users/${user.uid}`);
                // const snapshot = userRef.get();
                    try {
                        const userRef = firestore.collection("users").doc(user.uid);
                        if (userRef) {
                            userRef.get().then((querySnapshot) => {
                                try {
                                    if (querySnapshot.data()) {
                                        console.log(querySnapshot.data());
                                        const {address} = querySnapshot.data();
                                        // console.log(address);
                                        setCurrentAddress(address);
                                        
                                    }
                                    setLoading(false);

                                }catch(error) {
                                    setCurrentUser(null);
                                    console.log(error);
                                    //alert(error);
                                    setLoading(false);
                                }
                            })
                         }                    
                    
                    }catch(error) {
                        alert(error);
                    }
                
            }
            else 
            {
                setCurrentAddress(null);
                setLoading(false);
            }
            
        })
    }, [])

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <AuthContext.Provider value={{currentUser,currentUid,currentAddress}}>
            {children}
        </AuthContext.Provider>
    )
}

export const signInWithGoogle = () => {
    signInWithPopup(authConfig, authProvider)
      .then((result) => {
        const uid = result.user.uid;
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
  
        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("profilePic", profilePic);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  export const signOutGoogle = () => {
    authConfig.signOut()
  };