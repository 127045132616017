import React from "react";

export default function Footer() {
  return (
    <>
      {/* Main Footer */}
      <footer className="main-footer">
        The Connect 37
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1
        </div>
      </footer>
    </>
  );
}
