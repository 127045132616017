import React, { Component,useContext } from 'react';
import authConfig, { createUserDocument } from '../config';
import { AuthContext } from './Auth'

class Singup2 extends Component {
  
  state = { address: ''};

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { currentUser,currentUid } = useContext(AuthContext);
    const { address } = this.state;
    try {
      await createUserDocument(currentUid, { address });
    } catch (error) {
      console.log('error', error);
    }

    this.setState({ address: ''});
  };

  render() {
    const { address } = this.state;
    return (
      <div>
        <form className="signup-login" onSubmit={this.handleSubmit}>
          <h2>Signup</h2>

          <input
            type="name"
            name="address"
            value={address}
            onChange={this.handleChange}
            placeholder="บ้านเลขที่"
          />
          
          <button>ลงชื่อเข้าใช้</button>
        </form>
      </div>
    );
  }
}

export default Singup2;