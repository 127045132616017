import React, {useState,useContext,useEffect} from "react";
import { AuthContext } from './Auth'
import { Navigate} from 'react-router-dom'
import authConfig, { createUserDocument } from '../config';


const SignUp = () => {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const {currentUser,currentUid,currentAddress, loading } = useContext(AuthContext);

    useEffect(() => {
        if (currentAddress) {
          console.log("a>:"+currentAddress);
        }
      }, [currentAddress])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShouldRedirect(true);
        const {address} = e.target.elements;
        const address1= address.value;
        try {
            //  authConfig.createUserWithEmailAndPassword(email.value,password.value);
            if (address1 === "") {
                console.log("no address");
            }
            else {
                await createUserDocument(currentUid, { address1 });
                              
                
            }
            // setCurrentUser(true);

        } catch(error) {
            alert(error);
        }
    }
    if (loading) {
        return <p>Loading...</p>;
    }

    if (!currentUser) {
         return <Navigate to="/" />
    }
    if (currentAddress) {
        return <Navigate to="/home" />
    }
    
    return (
            <> 
                <div>
                {shouldRedirect? (
                        
                        <Navigate to="/load" />
                        
                        ) : (
                            <div className="container mt-5">
                            <h1>Sign Up {shouldRedirect}</h1>
                            <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">บ้านเลขที่</label>
                                <input type="text" name="address" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                            <button onClick={() => authConfig.signOut()} className="btn btn-danger">Sign Out</button>
                            </form>
                            </div>
                    )}
                </div>
            </>
    )
}

export default SignUp;