 import firebase from "firebase/compat/app";
 import 'firebase/compat/firestore';
 import 'firebase/compat/auth';

// const firebaseConfig = firebase.initializeApp ({
//     apiKey: "AIzaSyBIFVnPC7fWGLb_YELFHl_E3-MAU3yZquE",
//     authDomain: "cn37.yod.in.th", //"cn37-62a64.firebaseapp.com"
//     projectId: "cn37-62a64",
//     storageBucket: "cn37-62a64.appspot.com",
//     messagingSenderId: "847165532953",
//     appId: "1:847165532953:web:a46bc455aabb59afc13388",
//     measurementId: "G-PRKL0C44RS"
// });


import { initializeApp } from "firebase/app";
import "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
//import 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyBIFVnPC7fWGLb_YELFHl_E3-MAU3yZquE",
    authDomain: "cn37.yod.in.th", //"cn37-62a64.firebaseapp.com"
    projectId: "cn37-62a64",
    storageBucket: "cn37-62a64.appspot.com",
    messagingSenderId: "847165532953",
    appId: "1:847165532953:web:a46bc455aabb59afc13388",
    measurementId: "G-PRKL0C44RS"
};


initializeApp(firebaseConfig);
const authConfig = getAuth();

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();



export const createUserDocument = async (currentUid, additionalData) => {
    if (!currentUid) return;

    const userRef = firestore.doc(`users/${currentUid}`);
  
    const snapshot = await userRef.get();
  
    if (!snapshot.exists) {
      const { email } = '1emaol';
      const { address1 } = additionalData;
  
      try {
        await userRef.set({
          address: address1,
          createdAt: new Date(),
        });
      } catch (error) {
        console.log('Error in creating user', error);
      }
    }
  };

  export const Logout = async (currentUid, additionalData) => {
    authConfig.signOut();
    if (!currentUid) return;

    const userRef = firestore.doc(`users/${currentUid}`);
  
    const snapshot = await userRef.get();
  
    if (!snapshot.exists) {
      const { email } = '1emaol';
      const { address1 } = additionalData;
  
      try {
        await userRef.set({
          address: address1,
          createdAt: new Date(),
        });
      } catch (error) {
        console.log('Error in creating user', error);
      }
    }
  };

export const authProvider = new GoogleAuthProvider();
export default authConfig;

// export default firebaseConfig;
